import React, { useState } from 'react';
import { Box, Typography, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Button } from 'react-bootstrap';
import { useApi } from 'hooks/useAPI';
import { loginUser } from 'services/apis/newApis';
import { enqueueSnackbar } from 'notistack';
import logo from "assets/banners/logo12.png";

export default function Login({ initialStep, Email }) {
    const [phone, setPhone] = useState('');
    const [mpin, setMpin] = useState('');

    const [loading, callAPI] = useApi(loginUser);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Handle phone input change
    const handlePhoneChange = (newPhone) => {
        setPhone(newPhone);
    };

    const handleChangeMpin = (newValue) => {
        setMpin(newValue);
    };

    const handleSubmit = () => {

        callAPI({
            data: {
                phone: phone,
                mpin: mpin,
            },
        })
            .then((res) => {
                localStorage.setItem('Authorization', 'Bearer ' + res.token);
                console.log(res.token,"res.token",res.Message);
                enqueueSnackbar("Successfully Logged In!", { variant: 'success' });
                window.location.replace('/');
            })
            .catch((err) => {
                console.log(err);
                console.log("errer", err);
                  enqueueSnackbar("Phone number or mpin is wrong", { variant: "error" });
            });
    };

    return (
        <>


            <Stack sx={{ marginBottom: '5vh' }}>

                <Typography variant="h4" sx={{ fontWeight: '700' }}>
                    Welcome back!
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: '400' }}>
                    Login into your account and start analysis.
                </Typography>
            </Stack>

            <Stack spacing={3}>
                {/* Phone input */}
                <MuiTelInput
                    value={phone}
                    onChange={handlePhoneChange}
                    defaultCountry="IN" // Set default country to India
                    height={2}
                    fullWidth
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                                borderRadius: "0.5rem",
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                                borderRadius: "0.5rem",
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                                borderRadius: "0.5rem",
                            },
                        },
                    }}
                    inputProps={{
                        inputMode: 'numeric', // Enables numeric keyboard on mobile devices
                        pattern: '[0-9]*',   // Ensures numeric input
                    }}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault(); // Prevent non-numeric input
                        }
                    }}
                />

                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center', // Vertically center both elements
                    }}
                >
                    <Typography
                        variant="h6"
                        fontWeight="400"
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Vertically center the label
                            justifyContent: 'center',
                        }}
                    >
                        MPIN:&nbsp;
                    </Typography>

                    <MuiOtpInput
                        value={mpin}
                        onChange={(value) => {

                            const numericValue = value.replace(/[^0-9]/g,"");
            
                                handleChangeMpin(numericValue);
                            
                    
                        }}
                        length={4}  // Customize based on MPIN length
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '1.2rem',
                                textAlign: 'center',
                                height: '0.6rem', // Ensure input height matches label]
                                width: "1rem",
                                display: 'flex',
                                alignItems: 'center', // Center input content
                            },
                            '& .MuiInput-root': {
                                marginLeft: '8px', // Add spacing between the MPIN label and OTP input
                            },
                        }}
                        inputProps={{
                            inputMode: 'numeric', // Enables numeric keyboard on mobile devices
                            pattern: '[0-9]*',   // Ensures numeric input
                        }}
                      
                    />

                </Stack>
                <div
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit(); 
                        }
                    }}
                >
                    <Button
                        variant="outline-primary"
                        onClick={handleSubmit}
                        style={{
                            width: '100%',
                            padding: '10px',
                            fontWeight: '600',
                            marginTop: '20px', // Add space above the button
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            transition: 'all 0.2s ease-in-out',
                            backgroundColor: "#000",
                            color: "#fff",
                        }}
                        onMouseDown={(e) => {
                            e.target.style.transform = 'scale(0.95)';
                            e.target.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
                        }}
                        onMouseUp={(e) => {
                            e.target.style.transform = 'scale(1)';
                            e.target.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.transform = 'scale(1)';
                            e.target.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </Stack>
        </>
    );
}
