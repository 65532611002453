import React, { useState } from "react";
import { Box, Typography, useMediaQuery, useTheme, Grid } from "@mui/material";
import Login from "./login";
import LoginOtp from "./loginOtp";
import logo from "assets/banners/logo12.png";

export default function SignInSignUp() {
  const [isLogin, setIsLogin] = useState(true);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleForm = () => {
    setIsLogin((prev) => !prev);
  };

  return (
    <Grid
      container
      variant="main"
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundImage: `url(${"https://media.istockphoto.com/id/1302713357/vector/abstract-geometric-background.jpg?s=1024x1024&w=is&k=20&c=NeuMFNR8bQH9Ffhskz1BJ9vRM7Sr8YABkwk01IWPOFE="})`,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0rem",
      }}
    >
      {!smDown ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            margin: "auto",
            height: "100%",
          }}
        ></Grid>
      ) : (
        <></>
      )}

      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignContent: "center",
          height: "100%",
          backgroundColor: "#fff",
          // opacity:"0.8",
          padding: "0rem",
        }}
      >
        <Box
          sx={{
            width: smDown ? "100%" : "70%",
            padding: smDown ? "20px" : "40px",
            borderRadius: "12px",
          }}
        >
          {isLogin ? (
            <Grid container rowGap={5}>
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                }}
                lg={12}
              >
                <img
                  src={logo}
                  alt="ChartingHQ logo"
                  style={{ height: "4.5rem" }}
                />
                <Typography sx={{ fontSize: "2.5rem", fontWeight: "900" }}>
                  ChartingHQ
                </Typography>
              </Grid>

              <Grid
                item
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                }}
                lg={12}
              >
                <Login />
              </Grid>

              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginBottom: "4rem",
                  width: "100%",
                }}
                lg={12}
                sm={12}
              >
                <Box textAlign="center" marginTop="2vh">
                  <Typography variant="body2" color={theme.palette.grey[500]}>
                    Or
                  </Typography>

                  <Typography
                    marginTop={1}
                    variant="body2"
                    component="span"
                    color={theme.palette.grey[700]} // Slightly darker grey for better contrast
                  >
                    Don't have MPIN ?&nbsp;
                    <Typography
                      component="span"
                      onClick={toggleForm} // Simplified the click handler
                      sx={{
                        color: "#000080", // Use primary color for the link
                        fontWeight: "600",
                        fontSize: "0.8rem",
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline", // Underline on hover
                        },
                      }}
                    >
                      Send Otp
                    </Typography>
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                }}
                lg={12}
              >
                <Typography sx={{ fontSize: "0.85rem", fontWeight: "500" }}>
                  Copyright © 2024 ChartingHQ. All rights reserved.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container sx={{width:"100%",}}>
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  width:"100%",
                }}
                lg={12}
              >
                <img
                  src={logo}
                  alt="ChartingHQ logo"
                  style={{ height: "4.5rem" }}
                />
                <Typography sx={{ fontSize: "2.5rem", fontWeight: "900" }}>
                  ChartingHQ
                </Typography>
              </Grid>

              <Grid
                tem
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "6rem",
                  width:"100%",
                }}
                lg={12}
              >
                <LoginOtp />
              </Grid>

              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                  marginBottom: "4rem",
                  width: "100%",
                }}
                lg={12}
              >
                <Box textAlign="center" marginTop="2vh">
                  <Typography variant="body2" color={theme.palette.grey[500]}>
                    Or
                  </Typography>

                  <Typography
                    marginTop={1}
                    variant="body2"
                    component="span"
                    color={theme.palette.grey[700]} // Slightly darker grey for better contrast
                  >
                    <Typography
                      component="span"
                      onClick={toggleForm} // Simplified the click handler
                      sx={{
                        color: "#000080", // Use primary color for the link
                        fontWeight: "600",
                        fontSize: "0.8rem",
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline", // Underline on hover
                        },
                      }}
                    >
                      Sign In Instantly with Your MPIN
                    </Typography>
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                }}
                lg={12}
              >
                <Typography sx={{ fontSize: "0.85rem", fontWeight: "500" }}>
                  Copyright © 2024 ChartingHQ. All rights reserved.
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
